
.card { 
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.grid{
    display: flex;
    align-items: center;
    justify-content: center;
}

.container{
    display: flex;
    justify-content: center !important;
}

.button{
    margin-top:1rem !important;
    margin-right:1rem !important;
    height: 2.5rem;
}

.container_filter{
    display: flex;    
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    margin: 1rem;
    justify-content: center;
    align-items: flex-end;
}