.tableContainer {
  display: flex;
  flex-direction: column;
  height: 80vh;
  max-height: 800px;
  min-height: 400px;
}

.tableHeader {
  padding: 16px;
  background-color: #fff;
  z-index: 2;
}

.filterContainer {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.filterInput {
  flex: 1;
  min-width: 200px;
}

.exportButton {
  margin: 16px;
}

.tableWrapper {
  flex: 1;
  overflow: auto;
  min-height: 200px;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table th {
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  z-index: 1;
}

.table th, .table td {
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.table tbody tr:hover {
  background-color: #f0f0f0;
}

.paginationContainer {
  padding: 16px;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
}

@media (max-width: 768px) {
  .filterContainer {
    flex-direction: column;
  }

  .filterInput {
    width: 100%;
  }
}