.card { 
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 20rem;
    display: grid;
}

.boxBttn{
    margin: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
}