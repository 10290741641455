.head{
    padding-left: 1rem; 
    padding-right: 1rem; 
    padding-bottom: 1.25rem; 
}


.title { 
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
    line-height: 1.5rem; 
    color: rgb(39, 17, 17);
}

.subtitle { 
    margin-top: 0.25rem;
    max-width: 42rem;
    font-size: 0.875rem;
    line-height: 1.25rem; 
    color: rgb(107 114 128);
}

.body{
    overflow: hidden;
    background-color: rgb(255 255 255);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    border-radius: 0.5rem;
}

.titleline { 
    border: 1px solid;
	border-top-width: 1px;
    border-color: rgb(229 231 235);
}

.information { 
    background-color: rgb(249 250 251);
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
    padding-top: 1.25rem; 
    padding-bottom: 1.25rem; 
    padding-left: 1.5rem; 
    padding-right: 1.5rem; 
}

.information_white { 
    background-color: rgb(255, 255, 255);
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
    padding-top: 1.25rem; 
    padding-bottom: 1.25rem; 
    padding-left: 1.5rem; 
    padding-right: 1.5rem; 
}

.key { 
    font-size: 0.875rem; 
    line-height: 1.25rem; 
    font-weight: 500;
    color: rgb(107 114 128);
}

.value {
    display: grid;
    margin-top: 0px;
    font-size: 0.875rem;
    line-height: 1.25rem; 
    color: rgb(17 24 39);
    grid-column: span 2 / span 2;
}

.container_input {  
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.input{
    width: 50rem;
    
}