
.not_found_context {
    position: relative;
    height: 100vh;
}

.not_found_container {
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    max-width: 460px;
    width: 100%;
    text-align: center;
    line-height: 1.4;
}

.not_found_image {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 0 auto 50px;
    text-align: center;
    line-height: 1.4;
    z-index: 1;
}

.not_found_image_context{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ffa200;
    transform: rotate(45deg);
    border: 5px dashed #000;
    border-radius: 5px;
    z-index: 2;
}

.home_page_button {
    font-family: "Arimo", sans-serif;
    display: inline-block;
    padding: 10px 25px;
    background-color: #8f8f8f;
    border: none;
    border-radius: 40px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    margin-top: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    transition: .2s all;
    text-align: center;
    line-height: 1.4;
}