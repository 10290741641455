.cameraSelector {
    position: absolute;
    top: 60px; /* Adjusted to accommodate the toggle button */
    left: 20px;
    width: 100%;
    max-width: 400px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 8px;
    z-index: 10;
  }
  
  .toggleButton {
    position: absolute;
    top: 20px; /* Adjusted to place the button at the top */
    left: 20px;
    z-index: 10;
  }
  
  .videoContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
  }
  
  .qrcode {
    width: 100%;
    height: 100%;
  }
  
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .footerMenu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  
  .addBttn {
    margin: 10px 20px !important;
    width: 143px;
  }
  