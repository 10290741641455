.head{
    margin: 0.5rem;
    padding-left: 1rem; 
    padding-right: 1rem; 
}


.title { 
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
    line-height: 1.5rem; 
    color: rgb(39, 17, 17);
}

.subtitle { 
    margin-top: 0.25rem;
    max-width: 42rem;
    font-size: 0.875rem;
    line-height: 1.25rem; 
    color: rgb(107 114 128);
}