.styleGrid{
    overflow-x: hidden;
}

.card { 
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
    height: auto;
    width: 310px;  
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25));
    border-radius: 12px !important;
    box-shadow: none !important;
}

.cardContent{
    padding: 18px !important;
}

.styleList {
    width: 100%;
    max-width: 360px;
    background-color: white;
}

.styleList span{
    width: 100%;
    max-width: 360px;
    background-color: white;
    color: #1976d2;
    font-weight: bold;
}

.actions{
    display: table-row-group;
}

.actions span{
    font-size: 18px;
    margin-left: 8px;
}

.cardActions{
    margin-left: 2.5rem;
}