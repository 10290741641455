.speedDial {
    position: fixed;
    bottom: 20px; 
    right: 20px; 

    height: 320px;
    transform: translateZ(0px);
    flex-grow: 1;
}

.qrCodeAdd {
    position: fixed;
    bottom: 20px; /* distância do fundo da tela */
    right: 20px; /* distância da direita da tela */
}