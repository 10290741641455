.modal {
    background-color:#fff;
    border: 2px solid #000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra do modal */
    display: flex;
    position: fixed; /* Define a posição como fixa para centralizar na tela */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; /* Margem automática para centralizar o modal */
    align-items: center;
    justify-content: center;
    height: 90vh; /* Define a altura como 90% da viewport height */
    width: 90vw; /* Define a largura como 90% da viewport width */    
}


.modal-cardeneta {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 90%;
    max-width: 1200px;
    height: 90%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .modalTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #666;
  }
  
  .modalBody {
    flex-grow: 1;
    overflow-y: auto;
    padding: 24px;
  }