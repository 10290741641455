.fabApp{
    position: fixed;
    bottom: 20px; /* distância do fundo da tela */
    left: 20px; /* distância da direita da tela */
    z-index: 9999;
}

.cardHeaderContent{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    padding: 20px !important;
    border-radius: 15px !important;
}

.styleHeaderList{
    width: 100%;
    max-width: 360px;
    background-color: white;
}

.styleHeaderList span{
    width: 100%;
    max-width: 360px;
    background-color: white;
    font-weight: bold;
}

.cardTable { 
    margin-bottom: 1.5rem !important;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    margin-top: 2rem;
    width: 310px;  
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25));
    border-radius: 12px !important;
    box-shadow: none !important;
}

.cardTableContent{
    padding: 18px !important;
}

.styleTableList {
    width: 100%;
    max-width: 360px;
    background-color: white;
}

.styleTableList span{
    width: 100%;
    max-width: 360px;
    background-color: white;
    font-weight: bold;
}

.gridTable{
    height: 100%;
    padding: 40px 20px 12px;
}

.MuiDataGrid-cell, .MuiDataGrid-colCell {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 715px) and (min-width: 699px) {
    .cardTable { 
        width: 350px;
        margin-left: 25%;
        margin-right: 25%;
    }
}

@media screen and (max-width: 699px) {
    .cardTable { 
        width: 350px;
        min-width: 265px;
        margin-left: auto;
        margin-right: auto;
    }
    .cardHeaderContent{
        max-width: 300px;
    }   
}

.cardAction{
    margin-top: 6px;
    justify-content: center;
}

#bttnAction {
    min-width: 168px !important;
    margin-left: 8px;
    height: 27px;
}

.spanValidate {
    float: right;
    position: relative;
    z-index: 1;
  }
