.styleBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),
      0px 24px 38px 3px rgba(0,0,0,0.14),
      0px 9px 46px 8px rgba(0,0,0,0.12);
  padding: 32px;
}

.shareWithBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px;
}

.shareWithTitle {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card { 
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 20rem;
    display: grid;
}

.container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (max-width: 699px) {

    .buttonAtualizar{
        width: 50% !important ;
        margin-left: auto !important;
    }
    .buttonAtualizar{
        flex-wrap: wrap;
        align-content: center;
    }    
}

.buttonAtualizar{
    padding: 10px 20px 10px 20px !important;
    float: right;
    margin: 10px 20px 10px 20px !important;
    margin-left: auto !important;
    background-color: #1976d2;
    color: white !important;
}

.button{
    height: 60px;
    border-radius: 50% !important;
}

.boxBttn{
    display: flex;
    flex-direction: column;
}


.Sample input,
.Sample button {
  font: inherit;
}

.Sample header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
}

.Sample header h1 {
  font-size: inherit;
  margin: 0;
}

.Sample__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 10px 0;
  padding: 10px;
}

.Sample__container > * > * {
  margin: 10px;
}

.Sample__container__content {
  display: flex;
  max-width: 100%;
  flex-basis: 420px;
  flex-direction: column;
  flex-grow: 100;
  align-items: stretch;
}

.Rich_edit {
  margin: 1rem;
}


.sectionHeader{
  padding: 20px;
}


.cardHeader {  
  width: 100% !important;
  filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25));
  box-shadow: none !important;
}

.cardHeaderContent{
  padding: 18px !important;
}

.styleHeaderList{
  width: 100%;
  max-width: 360px;
  background-color: white;
}

.styleHeaderList span{
  width: 100%;
  max-width: 360px;
  background-color: white;
  font-weight: bold;
}

.addButton {
  position: fixed;
  bottom: 20px; /* distância do fundo da tela */
  right: 20px; /* distância da direita da tela */
  z-index: 99;
}
