.lista {
    max-width: 360px !important;
    max-height: 100% !important;
 }
 
 .btn_lista {
     padding: 12px 20px  !important;
     transition: background-color 0.2s !important;
     border-bottom: 1px solid lightgray !important;
     display: flex !important;
 }
 
 .icon_lista {
     min-width: 40px !important;
 }
 
 .text_lista {
     margin-left: 16px !important;
     margin-right: 10px !important;
 }
 