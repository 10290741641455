.speedDial {
    position: fixed;
    bottom: 20px; 
    right: 20px; 

    height: 320px;
    width: 320px;
    transform: translateZ(0px);
    flex-grow: 1;
}
