.styleBox {
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),
      0px 24px 38px 3px rgba(0,0,0,0.14),
      0px 9px 46px 8px rgba(0,0,0,0.12);
  padding: 32px;

  max-width: 90%;
  width: 400px;
  max-height: 90%;
}

.actionBtnBox {
  display: flex;
  align-items: center;
  gap: 20px;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
}

.typograpyBox {
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px !important;
}

.btnBox {
  width: 100%;
  max-width: 300px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnBox:hover {
  opacity: 0.8;
}
