.gridStudent {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

@media screen and (max-width: 740px){
    .gridStudent {
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
    }
}

.containerFile {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}
.cardHeaderContent{
    padding: 18px !important;
}

.sectionHeader{
    padding: 20px;
}

.cardHeader {  
    width: 100% !important;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25));
    box-shadow: none !important;
}

.styleHeaderList{
    width: 100%;
    max-width: 360px;
    background-color: white;
}

.styleHeaderList span{
    width: 100%;
    max-width: 360px;
    background-color: white;
    font-weight: bold;
}


.styleBox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}