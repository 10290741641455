.cardTable { 
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    height: fit-content;
    width: 310px;  
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25));
    border-radius: 12px !important;
    box-shadow: none !important;
}

.cardTableContent{
    padding: 18px !important;
}

.styleTableList {
    width: 100%;
    max-width: 360px;
    background-color: white;
}

.styleTableList span{
    max-width: 360px;
    background-color: white;
    font-weight: bold;
}

.gridTable{
    padding: 0px 20px 12px;
}

#checkBoxStyle{
    opacity: 1 !important;
    height: 50% !important;
    top: auto !important;
}

#spanCheckBox{
    width: 100%;
    color: #1976d2;
}

.listCards{
    padding-bottom: 10px;
}

@media screen and (max-width: 715px) and (min-width: 699px) {
    .cardTable { 
        width: 350px;
        margin-left: 25%;
        margin-right: 25%;
    }
}

@media screen and (max-width: 699px) {
    .cardTable { 
        width: 350px;
        min-width: 265px;
        margin-left: auto;
        margin-right: auto;
    }
}
