.divider{
    margin-right: 2rem !important;
}

.formControl { 
    margin-right: 2rem !important;
}

.formGroup{
    margin-left: 1rem !important;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
    display: flex;
    flex-direction: row;

}

@media screen and (max-width: 630px) {
    .divider { 
        display: none;
    }
    .lastDivider{
        display: none;
    }
    .formControl { 
        margin: auto !important;
    }
}

@media screen and (max-width: 500px) {
    .formControl { 
        margin-top: 10px !important;
    }
    .formGroup{
        flex-direction: column;
    }
}