.actions {
    text-align: center;
    margin-top: 0.75rem;
}

.codeActions:first-child {
    margin-right: 50px;
}

.spanAction {
    color: black;
    font-size: 0.875rem;
    margin-left: 10px;
}