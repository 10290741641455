.overlay {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgb(245, 242, 242); /* Cor de fundo para o X */
    padding: 4px 8px; /* Espaçamento interno do X */
    color: red; /* Cor do X */
    font-weight: bold; /* Negrito para o X */
  }
  
.thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
}

.thumbInner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
    position: relative; /* Adicionando posição relativa para poder posicionar o X */
}

.img {
    display: block;
    width: auto;
    height: 100%;
}