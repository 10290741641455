.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  margin-bottom: 1rem;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .buttonContainer {
    margin-top: 8px; /* Ajuste conforme necessário para o espaçamento desejado entre os elementos em colunas */
  }
}

.dateContainer {
  display: flex;
  align-items: center;
  z-index: 99999;
  margin-top: 8px;
}

.label {
  margin-right: 10px;
  font-weight: 500;
  color: #333;
}

.buttonContainer {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-left: 1rem;
}