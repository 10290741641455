.card { 
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    height: 100%;
    width: 310px;  
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25));
    border-radius: 12px !important;
    box-shadow: none !important;
}

.spanValidate {
  float: right;
  position: relative;
  margin-left: auto;
  z-index: 1;
}

.cardContent{
    padding: 18px !important;
}

.cardAction{
    margin-top: 6px;
    justify-content: center;
    display: block !important;
}

#bttnAction {
    min-width: 168px !important;
    height: 27px;
}

.styleGrid{
    display: contents;
}

.divBttn {
  padding: 12px;
  display: flex;
  justify-content: center;
}

.styleBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),
        0px 24px 38px 3px rgba(0,0,0,0.14),
        0px 9px 46px 8px rgba(0,0,0,0.12);
    padding: 32px;
}

.styleList {
    width: 100%;
    max-width: 420px;
    background-color: white;
}

.styleList span{
    width: 100%;
    max-width: 420px;
    background-color: white;
    font-weight: bold;
}

@media screen and (max-width: 715px) and (min-width: 699px) {
    .card { 
        width: 350px;
        margin-left: 25%;
        margin-right: 25%;
    }
}

@media screen and (max-width: 699px) {
    .card { 
        width: 350px;
        min-width: 265px;
        margin-left: auto;
        margin-right: auto;
    }
}

.cardAction span{
  font-size: 18px;
  margin-left: 8px;
}

.iconBttn {
  width: 50%
}

.modalBox {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    max-width: 500px;
    margin: auto;
    padding: 20px;
  }
  
  .shareWithBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
  }
  
  .shareWithTitle {
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .shareBtn {
    width: 100%;
    max-width: 300px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 25px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .shareBtnCopy {
    background-color: #fff !important;
    color: #212121 !important;
    border: 1px solid #212121 !important;
  }
  
  .shareBtn:hover {
    opacity: 0.8;
  }
  
  .shareBtnIcon {
    margin-right: 5px;
  }
  