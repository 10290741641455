.cardHeaderContent{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    padding: 20px !important;
    border-radius: 15px !important;
}

.styleHeaderList{
    width: 100%;
    max-width: 360px;
    background-color: white;
}

.styleHeaderList span{
    width: 100%;
    max-width: 360px;
    background-color: white;
    font-weight: bold;
}

@media screen and (max-width: 699px) {
    .cardHeaderContent{
        max-width: 300px;
    }   
}
